@use "src/assets/styles/colors";
@use "src/assets/styles/typography";
@use "src/assets/styles/breakpoints";
@import "../mixins";

div.nav-button {
    height: 29px;
    margin-top: 12px;
    padding-left: 26px;
    background-color: colors.$background;

    &.Mui-selected, &.Mui-selected:hover {
        @include nav-list-hover;
    }

    @media screen and (max-width: breakpoints.$lg) {
        height: 44px;
        background-color: colors.$secondary;
        color: colors.$background;
    }
}

div.nav-icon-container {
    justify-content: center;
    align-items: center;
    min-width: 0;
    width: 29px;
    height: 29px;
    border-radius: 2px;
    background-color: colors.$background-darker;

    @media screen and (max-width: breakpoints.$lg) {
        width: 44px;
        height: 44px;

        path {
            fill: colors.$secondary;
        }
    }
}

div.nav-text {
    align-content: center;
    padding-left: 14px;

    span {
        font-size: 15px;
        font-weight: typography.$semi-bold;

        @media screen and (max-width: breakpoints.$lg) {
            font-size: 20px;
        }
    }
}

.nav-collapse {
    margin-top: 19px;
}

.nav-line {
    width: calc(100% - 40px);
    float: right;
    border-left: 1px solid;
    border-color: colors.$border;

    @media screen and (max-width: breakpoints.$lg) {
        width: calc(100% - 48px);
    }
}

ul.sub-nav-list {
    color: colors.$text-lighter;

    :hover {
        color: colors.$secondary;
    }

    @media screen and (max-width: breakpoints.$lg) {
        color: colors.$background;

        :hover {
            color: colors.$background;
        }
    }
}

div.sub-nav-button {
    padding: 0;

    &.Mui-selected, &.Mui-selected:hover {
        background-color: colors.$background !important;
        color: colors.$secondary;

        .sub-nav-text span {
            font-weight: typography.$semi-bold;
        }

        @media screen and (max-width: breakpoints.$lg) {
            background-color: colors.$secondary !important;
            color: colors.$background;
        }
    }
}

.sub-nav-text {
    margin-left: 29px;
    padding: 5px 0 5px 0;

    span {
        font-size: 14px;

        @media screen and (max-width: breakpoints.$lg) {
            font-size: 16px;
        }
    }
}
