@use "../../../assets/styles/colors";
@use "../../../assets/styles/typography";

.status {
    padding: 2px 4px 2px 4px;
    font-size: 11px;
    font-weight: typography.$semi-bold;
}

.status-success {
    background-color: colors.$success-light;
    color: colors.$success;
}

.status-warning {
    background-color: colors.$warning-light;
    color: colors.$warning;
}

.status-error {
    background-color: colors.$error-light;
    color: colors.$error;
}
