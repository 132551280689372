@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

#top-bar-buttons-container {
    justify-content: right;
}

button.navbar-button {
    background-color: colors.$secondary !important;
    min-width: 108px;
    min-height: 47px;
    margin: 7px 0 6px 0;
}
