#home-layout {
    height: 100vh;
    margin: 0;
    align-items: center;
}

#home-children-container {
    align-items: center;
    justify-content: center;
}

#home-logo {
    padding-left: 54px;
    padding-top: 44px;
    max-height: 10vh;
}

#home-children-container {
    min-height: 90vh;
}
