@use "src/assets/styles/colors";
@use "src/assets/styles/typography";
@use "src/assets/styles/breakpoints";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

/* Global */
html, body {
    height: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: typography.$font-primary;
}

form {
    width: 100%;
}