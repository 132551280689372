/* Theme Colors */
$primary: #03D07E;
$primary-dark: darken($primary, 0.1);
$secondary: #2345FF;
$success: #03D07E;
$success-light: #DFF6EF;
$warning: #FF9300;
$warning-light: #F9F2E7;
$error: #E60047;
$error-light: #FEEDF2;

/* Text Colors */
$text: black;
$text-lighter: #53646E;
$text-link: #2345FF;

/* Background Colors */
$background: white;
$background-darker: #F8FAFB;

/* Border Colors */
$border: #DAE3F1;
$border-darker: #CDD8E8;

/* Other Colors */
$dove-gray: #626262;
