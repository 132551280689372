@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.items-container {
    background-color: colors.$background;
    overflow-y: auto;
    height: 100vh;
    padding: 34px 31px 34px 31px;

    @media screen and (max-width: breakpoints.$md) {
        padding: 16px;
    }
}

.checklist-container {
    background: colors.$background-darker;
    overflow-y: auto;
    height: 100vh;
    padding: 34px 31px 34px 31px;

    @media screen and (max-width: breakpoints.$md) {
        padding: 16px;
    }
}

button.edit-button, button.delete-button {
    padding: 0;
    height: 17px;
}

button.edit-button {
    svg {
        height: 14px;
        width: 14px;

        path {
            fill: colors.$dove-gray;
        }
    }
}

button.delete-button {
    svg {
        path {
            fill: colors.$error;
        }
    }
}

button.add-category-button {
    padding: 14px;
    margin: 0 0 14px 0;
    font-size: 13px;
    height: 44px;
}

div.header-container {
    align-content: end;
    justify-content: start;
    margin-bottom: 22px;
}

div.button-container {
    align-content: start;
    justify-content: end;
    margin-bottom: 22px;
}

.category-icon-container {
    height: calc(100% - 12px);
    margin-bottom: 12px;
    align-content: end;
}

div.question-link-container {
    justify-content: end;
    align-content: end;
    padding-bottom: 12px;

    a {
        font-size: 13px;
        color: colors.$text-link;
        cursor: pointer;
    }
}
