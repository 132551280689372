@use "../../../assets/styles/colors";
@use "../../../assets/styles/typography";

.score {
    padding: 2px 4px 2px 4px;
    font-size: 12px;
    font-weight: typography.$semi-bold;
    width: 40px !important;
    height: 25px !important;
    justify-content: center;
    align-content: center;
    color: white;
    border-radius: 2px;
}

.score-success {
    background-color: colors.$success;
}

.score-warning {
    background-color: colors.$warning;
}

.score-error {
    background-color: colors.$error;
}
