@use "src/assets/styles/breakpoints";

#home-banner {
    position: relative;
    height: 100vh;
}

#home-banner-img {
    filter: brightness(70%);
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

#home-banner-quote-container {
    position: absolute;
    bottom: 10%;
    color: #fff;
    text-align: center;
}

#home-banner-quote {
    font-size: 24px;
}

#home-banner-quote-author {
    font-size: 12px;
}

@media screen and (max-width: breakpoints.$lg){
    #home-banner {
        display: none;
    }
}
