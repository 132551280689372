@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

#topbar {
    border-bottom: 1px solid;
    border-color: colors.$border;
    min-height: 70px;
    align-items: center;
    position: sticky;

    @media screen and (max-width: breakpoints.$lg) {
        min-height: 60px;
    }
}


