@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.items-table {
    color: colors.$text-lighter;

    td.MuiTableCell-root {
        border: 1px solid colors.$border;
        padding: 11px 13px 11px 13px;

        &:first-child {
            width: 17px;
        }

        &:nth-child(3) {
            text-align: center;
            width: 17px;
        }

        .icon-container {
            justify-content: center;
            align-content: center;
        }
    }

    .draggable-row {
        cursor: move;
    }
}

div.drop-zone, div.drop-zone-error {
    align-content: center;
    justify-content: center;
    border: 1px dashed;
    height: 38px;
    margin-top: 5px;
    font-size: 13px;
}

div.drop-zone {
    border-color: colors.$border-darker;
    color: colors.$text-lighter;
}

div.drop-zone-error {
    border-color: colors.$error;
    color: colors.$error;
}
