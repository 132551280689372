@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

@mixin nav-list-hover {
    background-color: colors.$background !important;

    div.nav-text {
        color: colors.$secondary;

        @media screen and (max-width: breakpoints.$lg) {
            color: colors.$background;
        }
    }

    div.nav-icon-container {
        background-color: colors.$secondary !important;

        @media screen and (max-width: breakpoints.$lg) {
            background-color: colors.$background !important;
        }
    }

    svg {
        background-color: colors.$secondary !important;

        path {
            fill: colors.$background;
        }

        @media screen and (max-width: breakpoints.$lg) {
            background-color: colors.$background !important;

            path {
                fill: colors.$secondary;
            }
        }
    }

    @media screen and (max-width: breakpoints.$lg) {
        background-color: colors.$secondary !important;
        color: colors.$background;
    }
}
