@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";
@use "src/assets/styles/typography";
@import "mixins";

#navbar {
    min-height: 100vh;
    align-self: stretch;
    border-right: 1px solid;
    border-color: colors.$border;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (min-width: breakpoints.$md) {
        max-width: 220px;
    }

    @media screen and (max-width: breakpoints.$lg) {
        max-width: 100vw;
        background-color: colors.$secondary;
        border: none;
    }
}

#logo-container {
    justify-content: center;
    align-items: center;
    height: 165px;

    @media screen and (max-width: breakpoints.$lg) {
        position: relative;
        height: 86px;
    }
}

#navbar-logo {
    @media screen and (max-width: breakpoints.$lg) {
        display: none;
    }
}

#navbar-button {
    position: absolute;
    color: colors.$background;
    font-size: 13px;
    top: 10px;
    right: 5px;
    padding: 5px;

    @media screen and (min-width: breakpoints.$lg) {
        display: none;
    }
}

#navbar-button-icon {
    margin-right: 17px;
}

ul.nav-list {
    :hover {
        @include nav-list-hover;
    }
}

#account-container {
    justify-content: center;
    align-items: center;
    padding-bottom: 43px;

    @media screen and (max-width: breakpoints.$lg) {
        color: colors.$background;
        padding-top: 40px;
    }
}

#avatar-container {
    justify-content: center;
}

#avatar {
    width: 36px;
    height: 36px;
    background-color: colors.$secondary;

    @media screen and (max-width: breakpoints.$lg) {
        background-color: colors.$background;
        color: colors.$secondary;
    }
}

#name {
    width: 150px;
}

#logout-container {
    justify-content: center;
}

#logout-link {
    font-size: 13px;
    text-decoration: none;
    cursor: pointer;

    @media screen and (max-width: breakpoints.$lg) {
        color: colors.$background;
        font-size: 16px;
    }
}
