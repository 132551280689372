@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

#dashboard-container {
    height: 100vh;
}

#content {
    background: colors.$background-darker;
    overflow-y: auto;
    padding: 16px 39px 26px 39px;
    width: 100%;
    height: 100%;
    margin: 0;

    @media screen and (max-width: breakpoints.$md) {
        padding: 16px;
    }
}

#navbar-button-icon {
    margin-right: 17px;
}

#menubar-logo {
    height: 20px;
    width: 98px;
    margin-left: 29px;
}
