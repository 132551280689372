#form-snackbar {
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;

    #form-snackbar-alert {
        width: 100%;
        justify-content: center;
        border-radius: 0;
    }
}
