@use "src/assets/styles/colors";
@use "src/assets/styles/breakpoints";

.table-option-text {
    margin: 2px 0 0 5px;

    @-moz-document url-prefix() {
        margin: 0 0 0 5px;
    }
}

.table-option-icon {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &:hover {
        cursor: pointer;
        color: colors.$secondary;

        path {
            fill: colors.$secondary;
        }
    }
}

.options-cell {
    min-width: 180px;
    width: 180px;
}

.option-icons-container {
    justify-content: center;
}
