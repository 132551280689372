@use "src/assets/styles/colors";
@use "src/assets/styles/typography";

div.card-container {
    justify-content: center;
    padding-left: 16px !important;
}

div.card {
    width: 100%;
    border-radius: 2px;
    box-shadow: none;
    border: 1px solid colors.$border;

    div.MuiCardHeader-root {
        padding: 12px 16px 16px 12px;
        height: calc(100% - 115px);
        align-items: start;
    }

    span.MuiCardHeader-title {
        font-size: 15px;
        font-weight: typography.$semi-bold;
        padding-right: 12px;
        word-wrap: anywhere;
    }

    span.MuiCardHeader-subheader {
        font-size: 12px;
        color: colors.$text-lighter;
        padding-right: 12px;
        word-wrap: anywhere;
    }

    button.MuiIconButton-root {
        width: 25px;
        height: 25px;
        border-radius: 0;
        background-color: #F4F6FA;
        color: black;

        svg {
            height: 16px;
        }
    }

    div.MuiCardContent-root {
        padding: 10px 16px 26px 16px;
    }

    div.form-label-container {
        justify-content: right;
    }

    label.MuiFormLabel-root {
        font-size: 10px;
    }

    span.project-progress {
        border-radius: 5px;
        background-color: #F4F6FA;
    }

    div.MuiCardActions-root {
        border-top: 1px solid colors.$border;

        span {
            font-size: 12px;
            color: colors.$text-lighter
        }
    }
}

div.card-popover {
    .MuiPaper-root {
        border: 1px solid colors.$border;
        border-radius: 2px;
        box-shadow: none;
        min-width: 160px;
    }

    .MuiButtonBase-root {
        padding: 11px 15px 11px 15px;
    }

    ul {
        padding: 0;
    }

    span {
        font-size: 12px;
    }

    hr {
        border-color: colors.$border;
    }
}
